import React, { useState } from 'react'
import './WeatherApp.css'

import search_icon from "../assests/search.png"
import clear_icon from "../assests/clear.png"
import cloud_icon from "../assests/cloud.png"
import drizzle_icon from "../assests/drizzle.png"
import rain_icon from "../assests/rain.png"
import humidity_icon from "../assests/humidity.png"
import snow_icon from "../assests/snow.png"
import wind_icon from "../assests/wind.png"



const WeatherApp = () => {

    let api_key = "8c116f217ecd6267ec9dce3cd5a3d177";

    const [weather, setWeather] = useState(cloud_icon);

    const search = async () => {
        const element  = document.getElementsByClassName("cityInput");
        if(element[0].value === "") {
            return 0;
        }
        let url = `https://api.openweathermap.org/data/2.5/weather?q=${element[0].value}&units=Metric&appid=${api_key}`;

        let response = await fetch(url);
        let data = await response.json();

        const humidity = document.getElementsByClassName("humidity-percent");
        const wind = document.getElementsByClassName("wind-rate");
        const temprature = document.getElementsByClassName("weather-temp");
        const location = document.getElementsByClassName("weather-location");

        humidity[0].innerHTML = data.main.humidity+ " %";
        wind[0].innerHTML = Math.floor(data.wind.speed)+ " km/h";
        temprature[0].innerHTML = Math.floor(data.main.temp)+ " °c";
        location[0].innerHTML = data.name;

        if(data.weather[0].icon === "01d" || data.weather[0].icon === "01n")
        {
            setWeather(clear_icon);
        }
        else if(data.weather[0].icon === "02d" || data.weather[0].icon === "02n")
        {
            setWeather(cloud_icon);
        }
        else if(data.weather[0].icon === "03d" || data.weather[0].icon === "03n")
        {
            setWeather(drizzle_icon);
        }
        else if(data.weather[0].icon === "04d" || data.weather[0].icon === "04n")
        {
            setWeather(drizzle_icon);
        }
        else if(data.weather[0].icon === "09d" || data.weather[0].icon === "09n")
        {
            setWeather(rain_icon);
        }
        else if(data.weather[0].icon === "13d" || data.weather[0].icon === "13n")
        {
            setWeather(snow_icon);
        }
        else
        {
            setWeather(clear_icon);
        }

    }
  return (
    <div className='container'>
        <div className='top-nav'>
            <input type='text' className='cityInput' placeholder='search'/>
            <div className='search-icon' onClick={() => {search()}}>
                <img src={search_icon} alt=''/>
            </div>
        </div>
        <div className='weather-image'>
            <img src={weather} alt=''/>
        </div> 
        <div className='weather-temp'>
            24.c
        </div>
        <div className='weather-location'>
            Kathmandu
        </div>
        <div className='data-container'>
            <div className='element'>
                <img src={humidity_icon} alt='' className='icon'/>
                <div className='data'>
                    <div className='humidity-percent'>64%</div>
                    <div className='text'>Humidity</div>
                </div>
            </div>
            <div className='element'>
                <img src={wind_icon} alt='' className='icon'/>
                <div className='data'>
                    <div className='wind-rate'>18 km/h</div>
                    <div className='text'>Wind Speed</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WeatherApp
